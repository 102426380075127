
import { Component, Vue, Prop } from "vue-property-decorator";
import { BToast } from "bootstrap-vue";

@Component({
  components: {
    "b-toast": BToast,
  },
})
export default class AppNotification extends Vue {
  @Prop({ default: "" }) public id!: string;
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public messageMarkup!: string;
}
