import { Component, Vue } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { School } from "@/models/interface";

@Component({})
export default class GoogleMapMixin extends Vue {
  loader = new Loader({
    apiKey: "AIzaSyDlxV24w9chETQD7DPhFQ4ibg1cSVPgUYs",
    version: "weekly",
    libraries: ["places", "geometry"],
    language: "us",
  });
  map!: google.maps.Map;
  bounds = new google.maps.LatLngBounds();
  autoCompleteService = new google.maps.places.AutocompleteService();
  placesService!: google.maps.places.PlacesService | null;
  input!: HTMLInputElement;
  showInputBox = false;
  inputText = "";
  searchedLocation!: google.maps.LatLng;
  googleApiSearchResult: google.maps.places.AutocompletePrediction[] = [];
  searchInputText = "";
  markers: google.maps.Marker[] = [];
  currentlySearchedLocationMarker: google.maps.Marker | null = null;
  givenMainArray: School[] | null = null;
  center: google.maps.LatLngLiteral = { lat: 30.391830, lng: -92.329102 };
  infoWindow = new google.maps.InfoWindow();
  icon = {
    url: require("../assets/img/marker1.png"), // url
    scaledSize: new google.maps.Size(42,58), // size
  };
  currentLocationMarkerIcon = {
    url: require("../assets/img/marker2.png"), // url
    scaledSize: new google.maps.Size(50, 50), // size
  };

  iconFav = {
    url: require("../assets/img/marker3.png"), // url
    scaledSize: new google.maps.Size(42,58), // size
  };
  placeResults: google.maps.places.PlaceResult[] | null = null;
  markerIndexMarkedAsFevorite: number[] = [];
  getSearchText() {
    return this.searchInputText;
  }
  async startGoogleMap(id: string) {
    await this.loader.load();
    this.map = new google.maps.Map(document.getElementById(id) as HTMLElement, {
      center: this.center,
      zoom: 13,
    });

    
    this.placesService = new google.maps.places.PlacesService(this.map);
  }
  getQueryPrediction() {
    this.autoCompleteService.getPlacePredictions(
      { input: this.inputText },
      (data) => {
        if (data && data?.length > 1) {
          // console.log('predictions', data);
          this.googleApiSearchResult = data;
        } else {
          this.searchInputText = data ? data[0].description : "";
        }
      }
    );
  }
  
  showInfoWindow(singleMarker: google.maps.Marker, item: School) {
    singleMarker.addListener("mouseover", () => {
      if (item) {
        this.infoWindow.setContent(`
                            <div style="margin-top: 8px">
              <strong style="font-weight: bold; font-family"Public Sans"">${item.name ?? ""
          }</strong>
              <p> ${this.formatGradesServed(item.gradesServed) ?? ""}</p>
            </div>`);
      }
      if (this.infoWindow.get("currentlyOpend") != item.name) {
        this.infoWindow.open({ anchor: singleMarker, map: this.map, shouldFocus: false });
      }
      this.infoWindow.set("currentlyOpend", item.name);
    });
    singleMarker.addListener("click", () => {
      if (this.givenMainArray && this.givenMainArray.includes(item)) {
        const selectedIndex = this.givenMainArray.indexOf(item);
        console.log("item selected to view", item, "index", selectedIndex);
        const targetItem = this.$refs["item" + selectedIndex] as HTMLElement[];
        targetItem[0].scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          window.scrollBy(0, -50);
        }, 500);
      }
    });
  }
  updateMarker(index: number, school: School) {
    this.markers[index].setMap(null);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        parseFloat(school.latitude),
        parseFloat(school.longitude)
      ),
      map: this.map,
      draggable: false,
      icon: school.isFavorite ? this.iconFav : this.icon,
      label: {
        color: "white",
        fontSize: "12px",
        fontWeight: "600",
        text: school.id.toString(),
      },
    });
    marker.setMap(this.map);
  }
  removeAllMarkers() {
    this.markers.forEach(element => {
      if (element) element.setMap(null);
    });
    this.infoWindow.close();
    this.infoWindow = new google.maps.InfoWindow();
  }
  updateCurrentlySearchedLocationMarker(schoolSearchCriteria: any) {
    this.currentlySearchedLocationMarker?.setMap(null);
    this.setCurrentlySearchedLocationMarker(schoolSearchCriteria);
  }

  setCurrentlySearchedLocationMarker(schoolSearchCriteria: any) {
    let latLng;
    if (schoolSearchCriteria.latitude && schoolSearchCriteria.longitude) {
      latLng = new google.maps.LatLng(
        parseFloat(schoolSearchCriteria.latitude),
        parseFloat(schoolSearchCriteria.longitude)
      );
    } else {
      latLng = this.center;
    }
    const marker = new google.maps.Marker({
      position: latLng,
      map: this.map,
      draggable: false,
      icon: this.currentLocationMarkerIcon,
      /* label: {
        color: "white",
        fontSize: "12px",
        fontWeight: "600",
        text: schoolSearchCriteria.searchText ? schoolSearchCriteria.searchText.toString() : "",
      }, */
    });
    this.currentlySearchedLocationMarker = marker;
    marker.setMap(this.map);
    this.bounds.extend(latLng);
    marker.addListener("mouseover", () => {
      this.infoWindow.setContent(`
          <div>
          <strong style="margin-left: 10px;font-weight: bold;">${schoolSearchCriteria.searchText ?? ""
        }</strong>
          </div>
          `);
      this.infoWindow.open({ anchor: marker, map: this.map, shouldFocus: false });
    });
    marker.addListener("mouseout", () => {
      this.infoWindow.close();
    });
    
  }
  addMarkerOnMap(masterListOfSchools: School[]) {
    if (!this.infoWindow) {
      this.infoWindow = new google.maps.InfoWindow();
    }
    //console.log('school list', masterListOfSchools);

    this.givenMainArray = masterListOfSchools;
    if (masterListOfSchools) {
      this.markers = [];
      masterListOfSchools.forEach((item, ind) => {
        //console.log('item id', item, item.id);
        let latLng;
        if (item.latitude && item.longitude) {
          latLng = new google.maps.LatLng(
            parseFloat(item.latitude),
            parseFloat(item.longitude)
          );
        } else {
          latLng = this.center;
        }
        const marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
          draggable: false,
          icon: this.icon,
          label: {
            color: "white",
            fontSize: "12px",
            fontWeight: "600",
            text: item.id ? item.id.toString() : "",
          },
        });
        this.markers.push(marker);
        // create a bounds of all the marker
        this.bounds.extend(latLng);
        this.showInfoWindow(marker, item);

      });
      this.map.fitBounds(this.bounds);
    } else {
      alert("master school list is not defined");
    }
  }
  formatGradesServed(gradesList: string) {
    if (!gradesList) return "";
    const list = gradesList.split(",");
    return list && list.length > 1
      ? `${list[0]} - ${list[list.length - 2]}`
      : list[0];
  }
}
